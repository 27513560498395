module.exports = [{
      plugin: require('/builds/vermin/awst.in/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/builds/vermin/awst.in/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-129566442-1"},
    },{
      plugin: require('/builds/vermin/awst.in/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/vermin/awst.in/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    }]
