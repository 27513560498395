// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/builds/vermin/awst.in/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("/builds/vermin/awst.in/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-jsx": () => import("/builds/vermin/awst.in/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-affordable-housing-jsx": () => import("/builds/vermin/awst.in/src/pages/AffordableHousing.jsx" /* webpackChunkName: "component---src-pages-affordable-housing-jsx" */),
  "component---src-pages-austin-food-scores-jsx": () => import("/builds/vermin/awst.in/src/pages/AustinFoodScores.jsx" /* webpackChunkName: "component---src-pages-austin-food-scores-jsx" */),
  "component---src-pages-index-jsx": () => import("/builds/vermin/awst.in/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

exports.data = () => import("/builds/vermin/awst.in/.cache/data.json")

